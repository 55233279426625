import React from "react"

import {graphql, Link, useStaticQuery} from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {getPageUrl} from '../lib/helpers'

import Layout from '../containers/layout'

import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import RelatedPages from '../components/related-pages'
import SearchBar from '../components/search-bar'
import MetaSchemaSEO from '../components/seo';

import * as styles from './index.module.css'
import * as styles_preview from '../components/blog-post-preview-grid.module.css'

export const query = graphql`
  query {
    indexQuery: strapi{ siteSetting {
      id
      site_name
      default_seo_description
      featured_pages {
        id
        title
        slug
        page_type {
          name
          slug_prefix
        }
        parent {
          title
          title_short
          slug
          page_type {
            name
            slug_prefix
          }
        }
        site_section: page_type{
          name
          slug_prefix
        }
        main_image {
          url
          caption
          alternativeText
          imageFile{
            childImageSharp{
              gatsbyImageData(
                width: 400,
                aspectRatio: 1,
                transformOptions: {cropFocus:ENTROPY})
            }
          }
        }
      }
    }}
    strapi {
      pages(
        sort: "published_at:desc"
        limit: 1
        publicationState: LIVE
        where: { is_featured: "true" }
      ) {
        id
        is_featured
        slug
        page_type {
          name
          slug_prefix
        }
        site_section: page_type{
          name
          slug_prefix
        }
        title
        title_short
        primary_topic
        published_at
        parent {
          title
          title_short
          slug
          page_type {
            name
            slug_prefix
          }
        }
        page_value
        main_image {
          url
          alternativeText
          caption
          imageFile{
            childImageSharp{
              gatsbyImageData(
                width: 1000,
                aspectRatio: 2,
                transformOptions: {cropFocus:ENTROPY}
              )
            }
          }
        }
      }
    }
    featuredTopPages: strapi {
      pages(
        sort: "published_at:desc"
        limit: 4
        publicationState: LIVE
        where: { is_featured: "true" }
        start: 1
      ) {
        id
        is_featured
        slug
        page_type {
          name
          slug_prefix
        }
        site_section: page_type{
          name
          slug_prefix
        }
        title
        title_short
        primary_topic
        published_at
        parent {
          title
          title_short
          slug
          page_type {
            name
            slug_prefix
          }
        }
        page_value
        main_image{
          url
          alternativeText
          caption
          imageFile{
            childImageSharp{
              gatsbyImageData(
                width: 300,
                aspectRatio: 1,
                transformOptions: {cropFocus:ENTROPY}
              )
            }
          }
        }
      }
    }
    newsPages2: strapi{ pages(
      sort: "created_at:desc"
      limit: 16
      start: 5
      publicationState: LIVE
      ) {
          id
          slug
          page_type {
            name
            slug_prefix
          }
          site_section: page_type{
            name
            slug_prefix
          }
          title
          excerpt
          published_at
          parent {
            title
            title_short
            slug
            page_type {
              name
              slug_prefix
            }
          }
          main_image{
            url
            alternativeText
            caption
            imageFile{
              childImageSharp{
                gatsbyImageData(
                  width: 400,
                  aspectRatio: 1,
                  transformOptions: {cropFocus:ENTROPY}
                )
              }
            }
          }
        }
      },
    newsPages: strapi{ pages(
      sort: "created_at:desc"
      limit: 5
      publicationState: LIVE
      ) {
          id
          slug
          page_type {
            name
            slug_prefix
          }
          site_section: page_type{
            name
            slug_prefix
          }
          title
          authors {
            id
            name
            slug
            main_image {
              url
              caption
              alternativeText
              imageFile{
                childImageSharp{
                  gatsbyImageData(
                    quality: 80,
                    width: 50,
                    aspectRatio: 1,
                    transformOptions: {cropFocus:ENTROPY})
                }
              }
            }
          }
          excerpt
          published_at
          parent {
            title
            title_short
            slug
            page_type {
              name
              slug_prefix
            }
          }
          main_image{
            url
            alternativeText
            caption
            imageFile{
              childImageSharp{
                gatsbyImageData(
                  width: 400,
                  aspectRatio: 1,
                  transformOptions: {cropFocus:ENTROPY}
                )
              }
            }
          }
        }
      },
      relatedPages: strapi{ pages(
        sort: "created_at:desc"
        limit: 4
        publicationState: LIVE
        where: { is_featured: "true" }
        start: 5
        ) {
            id
            slug
            page_type {
              name
              slug_prefix
            }
            site_section: page_type{
              name
              slug_prefix
            }
            title
            excerpt
            published_at
            parent {
              title
              title_short
              slug
              page_type {
                name
                slug_prefix
              }
            }
            main_image{
              url
              alternativeText
              caption
              imageFile{
                childImageSharp{
                  gatsbyImageData(
                    width: 300,
                    aspectRatio: 1,
                    transformOptions: {cropFocus:ENTROPY}
                  )
                }
              }
            }
          }
        }
  }
`

// markup
const IndexPage = () => {
  const data = useStaticQuery(query);
  const heroPages = data.strapi.pages
  const featuredPages = data.featuredTopPages.pages
  const postNodes = data.newsPages.pages
  const postNodes2 = data.newsPages2.pages
  const relatedNodes = data.relatedPages.pages
  const featuredSitePages = data.indexQuery.siteSetting.featured_pages


  return (
    <Layout>
      <MetaSchemaSEO
      />
      <Container>
          <div className={styles.hero}>
            {heroPages &&
              heroPages.map(post => (
                <Link to={getPageUrl(post)} key={post.id}>
                  <h2>{post.title}</h2>
                  { post.main_image && 
                  <GatsbyImage 
                    className={styles.heroImage} 
                    image={getImage(post.main_image.imageFile)} 
                    alt={post.title} />
                  }
                </Link>
              ))}
          </div>
        { featuredSitePages && (
          <div className={styles.featuredPages}>
            <ul className={styles_preview.grid_two}>
              <li>

                <BlogPostPreviewList
                  nodes={featuredSitePages.slice(0,1)}
                />
              </li>
              <li>

                <BlogPostPreviewList
                  nodes={featuredSitePages.slice(1,2)}
                />
              </li>
            </ul>
          </div>
        )
        }
        { process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS && 
          <SearchBar></SearchBar>
        }
        </Container>

      {postNodes && (
        <>
          <BlogPostPreviewList
            nodes={postNodes}
          />
        </>
      )}
      <Container>
        { process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS && 
          <SearchBar></SearchBar>
        }

      </Container>

      {featuredSitePages && featuredSitePages.length > 0 && (<RelatedPages nodes={featuredSitePages}></RelatedPages>)}
      <Container>
        {postNodes2 && postNodes2.length > 0 &&
          <div className={styles.latestPages}>
            <span>Recently Published</span>
            <ul className={styles.listPages}>
                {postNodes2.map(post => (
                    <li key={post.id}>
                      <Link to={getPageUrl(post)}>{post.title}</Link>
                    </li>
                ))}
            </ul>
          </div>
        }
      </Container>
    </Layout>
  )
}

export default IndexPage
